import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column",
  "data-kt-landing": "true"
}
const _hoisted_2 = { class: "fw-normal landingTitle" }
const _hoisted_3 = { class: "landingSubtitle pb-5" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "mr-2 pt-5 textoCentrado" }
const _hoisted_6 = { class: "mr-2 pt-5 textoCentrado mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.i18n("planCorresponsables")), 1),
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.i18n("formularioInscripcion")), 1),
      _createElementVNode("div", {
        class: "textoJustificado",
        innerHTML: _ctx.$t('descripcionFormularioProfesionales')
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: "/formulario-profesionales",
          class: "btn btn-primary botonForm",
          "active-class": "active"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.i18n("cumplimentarCuestionarioBtn")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          to: "/formulario-actualizacion-profesionales",
          class: "btn btn-primary botonForm",
          "active-class": "active"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.i18n("actualizarProfesional.boton")), 1)
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}